import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import footerdata from '../../data/footerdata.json';

class Footer_v1 extends Component {

    componentDidMount() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = "Footer logo"
        const inlineStyle = {
            backgroundImage: 'url('+publicUrl+footerdata.footerbg+')'
        }

        return (
             <footer className="footer-area style-two">
              <div className="container">
                <div className="footer-top">
                  <div className="row">
                    <div className="col-sm-4 logo-div">
                      <a className="footer-logo" href="#"><img src={publicUrl+"Nexstoc Final Logo.png"} alt={ imgattr } /></a>
                      {/* <p style={{color : 'black', marginTop:'2px'}}> Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus. Scelerisque felis imperdiet proin fermentum leo. Amet volutpat consequat mauris nunc congue.
                      </p> */}
                    </div>
                    <div className="col-sm-8">
                      <div className="footer-social text-sm-right">
                        <span>FOLLOW US</span>
                        <ul className="social-icon">
                         { footerdata.socialicon.map( ( item, i )=>
                            <li key={i}>
                              <a href={ item.url } target="_blank"><i className={ item.icon } /></a>
                            </li>
                          ) }
                        </ul>
                      </div>  
                    </div>
                  </div>
                </div>
                <div className="footer-bottom">
                  <div className="row">
                    {/* <div className="col-lg-3 col-sm-6" style={{color : 'black'}}>
                      <div className="widget widget_nav_menu">
                        {/* <h4 className="widget-title">{ footerdata.popularserces.title }</h4>
                        <ul>
                          { footerdata.popularserces.links.map( ( item, i )=>
                              <li key={ i }><a href={ item.url } >{ item.title }</a></li>
                           ) }
                          
                        </ul> 
                       

                      </div>
                    </div> */}
                    <div className="col-lg-4 col-sm-6">
                      <p style={{color : 'black', marginTop:'2px'}}> Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus. Scelerisque felis imperdiet proin fermentum leo. Amet volutpat consequat mauris nunc congue.
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="widget widget_nav_menu">
                        <h4 className="widget-title">{ footerdata.Homepress.title }</h4>
                        <ul>
                          { footerdata.Homepress.links.map( ( item, i )=>
                              <li key={ i }><Link to={ item.url } >{ item.title }</Link></li>
                          ) }
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                      <div className="widget widget_nav_menu">
                       
                           <h4 className="widget-title" >{ footerdata.quicklink.title }</h4>
                         
                        <ul>
                         { footerdata.quicklink.links.map( ( item, i )=>
                              <li key={ i }>{ item.title }</li>
                          ) }
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-sm-6">
                     <h4 className="widget-title">OUR NEWSLETER</h4>
                        <p style={{color:'black'}}>Tamen quem nulla quae legam multos aute sint culpa legam noster magna veniam enim veniam illum dolore legam minim quorum culpa amet magna export quem marada parida nodela caramase seza.</p>
                      <form className="widget widget-subscribe">
                        <div className="rld-single-input">
                          <input type="text" placeholder="Full Name" />
                        </div>
                        <div className="rld-single-input">
                          <input type="text" placeholder="Your@email.com" />
                        </div>
                        <button className="btn btn-yellow w-100">Subscribe</button>
                      </form>
                    </div> */}
                  </div>
                </div>
                <div className="copy-right text-center" dangerouslySetInnerHTML={{__html: footerdata.copyrighttext}}></div>
              </div>
            </footer>

        )
    }
}


export default Footer_v1
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { EmojiObjects, ExpandLess, ExpandMore } from "@material-ui/icons";
import {Link as ScrollLink} from 'react-scroll'

import HomeIcon from "@material-ui/icons/Home";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PublicIcon from '@material-ui/icons/Public';
import PersonIcon from "@material-ui/icons/Person";
import CropFreeIcon from '@material-ui/icons/CropFree';

import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import NavbarHeader from "./NavbarHeader";

const drawerHeadersData = [
  {
    label: "Home",
    href: "/",
    i: 4,
  },
  {
    label: "Products",
    links: [
      {label : 'Overview', href : '/'} ,
      {label : 'Accessories', href : '/'}],
    i: 0,
  },
  {
    label: "Platform",
    links: [
      {label : 'Feature', href : 'features'} ,
      {label : 'Process', href : 'process'}],
    i: 1,
  },
  {
    label: "Customers",
    links: [
      // {label : 'Industries', href : '/'} ,
      {label : 'Testimonials', href : 'testimonial'},
      {label : 'Case Study', href : 'casestudy'}],
    i: 2,
  },
  {
    label: "Company",
    links: [
      {label : 'About Us', href : '/about'} ,
      {label : 'Blogs', href : '/blogs'},
      {label : 'Career', href : '/career'},
      {label : 'Contact Us', href : '/contact-us'}],
    i: 3,
  }
];

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "white",
    color: "black",
    padding: "5px",
    // paddingRight: "79px",
    // paddingLeft: "118px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  menuButton: {
    fontFamily: "Roboto",
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
  },
  drawerContainer: {
    padding: "10px 15px",
    width: "275px",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingRight: "10px",
    paddingTop: "10px",
    color: "#18D26E",
  },
  menuButtonList: {
    marginRight: "auto",
    float: "right",
  },
  expandable : {
    overflow: "hidden",
  
    transition: "all .5s ease-in-out",
    transformOrigin: "left top",
    transform: "scaleY(0)"
   },
   
  submenu : {
    display : 'none'
  }
}));


function Navbar() {
  const {
    header,
    logo,
    menuButton,
    toolbar,
    drawerContainer,
    closeIcon,
    menuButtonList,
    expandable,
    submenu
  } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const [showSubMenu, setSubMenu] = useState(false);

  const [open, setOpen] = useState([
    false,
    false,
    false,
    false
  ]);

  let publicUrl = process.env.PUBLIC_URL + "/";

  const { mobileView, drawerOpen } = state;

  const location = useLocation();
  const pathname = location.pathname
 console.log(pathname);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
<div className="nav-right-part nav-right-part-desktop readeal-top m-2">
  //                      <p style={{ color:'black'}}>1-888-463-5254</p>
  //                    </div>    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

  }, []);

  //Get list of previleges from localstorage or firebase
  // 

  //  handle drawer open and close
  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));

  const drawer = () => {
    return (
      <>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className="mt-2">
            <span className={closeIcon}>
              <ClearIcon onClick={handleDrawerClose} />
            </span>

            <div className={drawerContainer}>{getDrawerChoices()}</div>
          </div>
        </Drawer>
      </>
    );
  };

  // const displayDesktop = () => {
  //   return (
  //     <div>
  //                <div className="navbar-area">
  //                <nav className="navbar navbar-area navbar-expand-lg">
  //                  <div className="container nav-container">
  //                    <div className="responsive-mobile-menu">
  //                      <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
  //                        <span className="icon-left" />
  //                        <span className="icon-right" />
  //                      </button>
  //                    </div>
  //                    <div className="logo readeal-top">
  //                      <Link to="/"><img src={publicUrl+"Nexstoc Final Logo.png"} alt="logo" /></Link>
  //                    </div>
  //                    {/* <div className="nav-right-part nav-right-part-mobile">
  //                      <Link className="btn btn-yellow" to="/add-property">LOGIN</Link>
  //                    </div> */}
  //                    <div className="collapse navbar-collapse" id="realdeal_main_menu">
  //                      <ul className="navbar-nav menu-open readeal-top">
  //                        <li className="current-menu-item">
  //                          <a href="#">Home</a>
                           
  //                        </li>
  //                        <li className="menu-item-has-children">
  //                          <a href="#">Products</a>
  //                          <ul className="sub-menu">
  //                            <li><Link to="/">Overview</Link></li>
  //                            <li><Link to="/">Accessories</Link></li>
  //                          </ul>
  //                        </li>
  //                        <li className="menu-item-has-children">
  //                          <a href="#">Platform</a>
  //                          <ul className="sub-menu">
  //                            <li><Link to="/">Feature</Link></li>
  //                            <li><Link to="/">Process</Link></li>
  //                          </ul>
  //                        </li>
  //                        <li className="menu-item-has-children">
  //                          <a href="#">Customers</a>
  //                          <ul className="sub-menu">
  //                          <li><Link to="/">Industries</Link></li>
  //                            <li><Link to="/">Testimonials</Link></li>
  //                            <li><Link to="/">Case Study</Link></li>
  //                          </ul>
  //                        </li>
  //                        <li className="menu-item-has-children">
  //                          <a href="#">Company</a>
  //                          <ul className="sub-menu">
  //                          <li><Link to="/about">About Us</Link></li>
  //                            <li><Link to="/">Blogs</Link></li>
  //                            <li><Link to="/">Career</Link></li>
  //                            <li><Link to="/">Contact Us</Link></li>
  //                          </ul>
  //                        </li>
                      
  //                      </ul>
  //                    </div>
  //                    <div className="nav-right-part nav-right-part-desktop readeal-top m-2">
  //                      <Link className="btn  btn-yellow btn-small" style={{color:'white'}} to="/">Login </Link>
  //                    </div>
  //                    
  //                  </div>
  //                </nav>
  //              </div>
  //            </div>
  //   );
  // };

  const displayMobile = () => {
    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        {nexstocLogo}
        {drawer()}
        <div style={{ marginLeft: "auto" }}>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  

  // clicked on dropdown menu
  const handleClick = (i) => {
    setOpen(open.map((val, index) => (index == i ? !val : val)));
  };

  const getDrawerChoices = () => {
    let index = 0;
    return (
      <>
        {nexstocLogo}
        <br />
        <Divider />
        <List component="nav" aria-labelledby="nested-list-subheader">
        {drawerHeadersData.map(data => {
          return (
            <>
            {data.i !== 4 ? (
              // open.map((val, index) => {
                // return (
              <div key={data.i}>
                <ListItem
                  key={data.i}
                  button
                  onClick={(e) => handleClick(data.i)}
                >
                  <ListItemIcon>
                    {data.i === 0 ? <ShoppingBasketIcon /> : data.i === 1 ? <CropFreeIcon /> : data.i === 2 ? <PersonIcon /> : <PublicIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography style={{ fontWeight: "Bold" }}>
                        {data.label}
                      </Typography>
                    }
                  />
                  {open[data.i] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open[data.i]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding >
                    {data.links.map((item, pos) => {
                      let smooth = data.label === 'Company' ? ' ' : true
                      return (
                        <ListItem
                          key={pos}
                          button
                          {...{
                            component: pathname === '/' && data.label !== 'Company' ? ScrollLink : RouterLink,
                            to: pathname !== '/' && data.label !== 'Company' ? '/#'+item.href : item.href,
                            color: "inherit",
                            smooth,
                            style: {
                              textDecoration: "none",
                              color: "black",
                              display: "flex",
                              flexDirection: "row",
                              alignItems : "center",
                              justifyContent : "center",
                              padding: "5px",
                              marginLeft : "15px"
                            },
                            onClick : handleDrawerClose,
                            key: item.label,
                            
                          }}
                        >
                          <ListItemIcon><TrendingFlatIcon /> </ListItemIcon>
                          <ListItemText primary={item.label} style={{}} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
               </div>
            
            
           ) : <>
          <ListItem
            button
            {...{
              component: RouterLink,
              onClick : handleDrawerClose,
              to: data.href,
              color: "inherit",
              style: {
                textDecoration: "none",
                color: "black",
              },
              key: data.i,
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontWeight: "Bold" }}>
                  {data.label}
                </Typography>
              }
            />
          </ListItem>
         
            </>
            }
            </>
          );
        })}
        ;
        </List>
      </>
    );
  };

  const nexstocLogo = (
    <Typography variant="h6">
      <Link
        {...{
          component: RouterLink,
          to: "/",
        }}
      >
        <img
          src={publicUrl + "Nexstoc Final Logo.png"}
          alt="logo"
          className={mobileView ? "mob-logo" : "logo"}
        />
      </Link>
    </Typography>
  );

  const getMenuButtons = () => {
    return (
      <div>
        {/* <li className="menu-item-has-children">
          <PersonIcon size="large" />
          <ul className="sub-menu">
            <li>
              <GetEmail />
            </li>
            <li>
              <LogoutButton onClick={(e) => logOutUser()}>Log Out</LogoutButton>
            </li>
          </ul>
        </li> */}

        <Link className="btn btn-yellow" to="/">LOGIN</Link>
        {/* <ColorButton onClick={(e) => logOutUser()}>Log Out</ColorButton> */}
      </div>
    );
  };

  // const getPageName = () => {
  //   const currentURL = window.location.href;

  //   const pathname = window.location.pathname;
  //   return matchNavLinkToLabel[pathname];
  // };

  return (
    <>
      {mobileView ? (
      <AppBar className={header}>
         {displayMobile() }
      </AppBar> ) : <NavbarHeader /> }
    </>
  );
}


export default Navbar;

// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// class Navbar extends Component {

//     render() {
//         let publicUrl = process.env.PUBLIC_URL+'/'
//         let imgattr = 'logo'
//         let anchor = '#'
//         return (
//             <div>
//                 <div className="navbar-area">
//                 <nav className="navbar navbar-area navbar-expand-lg">
//                   <div className="container nav-container">
//                     <div className="responsive-mobile-menu">
//                       <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
//                         <span className="icon-left" />
//                         <span className="icon-right" />
//                       </button>
//                     </div>
//                     <div className="logo readeal-top">
//                       <Link to="/"><img src={publicUrl+"Nexstoc Final Logo.png"} alt="logo" /></Link>
//                     </div>
//                     <div className="nav-right-part nav-right-part-mobile">
//                       <Link className="btn btn-yellow" to="/add-property">LOGIN</Link>
//                     </div>
//                     <div className="collapse navbar-collapse" id="realdeal_main_menu">
//                       <ul className="navbar-nav menu-open readeal-top">
//                         <li className="current-menu-item">
//                           <a href="#">Home</a>
                          
//                         </li>
//                         <li >
//                           <a href="#">Products</a>
                          
//                         </li>
//                         <li className="menu-item-has-children">
//                           <a href="#">Platform</a>
//                           <ul className="sub-menu">
//                             <li><Link to="/">Feature</Link></li>
//                             <li><Link to="/">Process</Link></li>
//                           </ul>
//                         </li>
//                         <li className="menu-item-has-children">
//                           <a href="#">Customers</a>
//                           <ul className="sub-menu">
//                           <li><Link to="/">Industries</Link></li>
//                             <li><Link to="/">Testimonials</Link></li>
//                             <li><Link to="/">Case Study</Link></li>
//                           </ul>
//                         </li>
//                         <li className="menu-item-has-children">
//                           <a href="#">Company</a>
//                           <ul className="sub-menu">
//                           <li><Link to="/">About Us</Link></li>
//                             <li><Link to="/">Blogs</Link></li>
//                             <li><Link to="/">Career</Link></li>
//                             <li><Link to="/">Contact Us</Link></li>
//                           </ul>
//                         </li>
                        
//                       </ul>
//                     </div>
//                     <div className="nav-right-part nav-right-part-desktop readeal-top">
//                       <Link className="btn btn-yellow" to="/">Login </Link>
//                     </div>
//                   </div>
//                 </nav>
//               </div>
//             </div>
//         )
//     }
// }


// export default Navbar
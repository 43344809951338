import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';

class BannerV3 extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        // let imagealt = 'image'
        let data = sectiondata.bannerv3

        const inlineStyle = {
            // backgroundImage: 'url('+publicUrl+'assets/img/banner/Home-bg.png),linear-gradient(180deg,rgba(56,54,54,.6),rgba(255,255,255,.15))',
            // linear-gradient(45deg,rgba(56,54,54,.6),rgba(108,161,154,.9)),
            // 'url('+publicUrl+'/assets/img/bg/3.png)'
        }
        const headerText = {
          color : '#303030',
          fontWeight: '700',
          lineHeight: '60px'
        }

        const headerUnderline = {
          backgroundImage: 'url("../../assets/img/others/underline.svg")',
          backgroundPosition: '0 1.06em',
          backgroundRepeat: 'repeat-x',
          // transition: 'width 2s'
          // background-size: 10px 9px;
          // color: #f2f3f8;
        }
        const content = {
          color : '#A6A6A6',
          fontSize : '18px',
          fontWeight : '900px',
          lineHeight : '30px',
          paddingTop: '16px',
        }

        const requestDemoBtn = {
          background: '#F9C31D',
          boxShadow: '4px 10px 40px rgba(48, 48, 48, 0.2)',
          borderRadius: '10px',
          color: '#fff',
          padding: '10px',
          border: 'none'
        }

    return <div>
              <div className="banner-area jarallax" style={ inlineStyle }>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 col-md-10">
                        <div className="banner-inner-wrap">
                          <div className="banner-inner w-100" style={{margin:'-15px'}}>
                            <h1 className="title" style={headerText}> Anything, anywhere to gear up your <span style={headerUnderline}>workplace</span></h1>
                            <p className="content" style={content} dangerouslySetInnerHTML={{__html: data.subtitle}}></p>
                            <div className="rld-banner-search">
                                <button style={requestDemoBtn}>
                                  <Link  to="/">Request a Demo</Link>
                                </button>
                              
                            </div>
                          </div>
                        </div>   
                      </div>
                      <div style={{marginLeft:'90px'}} className="col-xl-5 col-lg-6 d-lg-block d-none">
                        <div style={{top: '120px'}}  className="thumb-wrap">
                          <img src={publicUrl+"assets/img/banner/headerimg.svg"} alt="img" />
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-6 d-lg-none d-block">
                        <div className="header-img">
                          <img src={publicUrl+"assets/img/banner/headerimg.svg"} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

          </div>

        }
}

export default BannerV3
import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import OurTeam from './OurTeam';
// import parse from 'html-react-parser';

class AboutUs extends Component {

//   constructor(props) {
//     super(props)
// }
componentDidMount() {
  window.scrollTo(0, 0)
}
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        // let imagealt = 'image'
        // let data = sectiondata.ourteam
        // const innerBg = {
          // backgroundColor : '#f3f3f6'
        // }

    return <div className="about-area pd-top-100">
        <div className="container">
              <h3 style={{textAlign:"center"}}>About Us</h3>

          <div className="row justify-content-center align-items-center aboutus mt-5 ">
           <div className="col-12 col-md-12 pb-3">
                <img className="rounded-circle float-left mr-3 " src={publicUrl+"assets/img/client/user-1.jpg"} alt="founder"/>
                      <h5 className="mt-0">Founder</h5>
                      <p >Any business idea becomes successful when executed. Working on the same path Mr. Mahesh Ravriya brought about the emergence of NEXSTOC. Having served in the office supplies industry for more than 20 years; he has seen the transition of the company’s requirements beginning from a full paper stacked environment to a mechanized world running on computerized data and software. NEXSTOC is a dream brought into existence to reach out to every possible industry beginning with corporate houses to healthcare, retails, banking, educational institutions, and much more. He believes that this can be executed by offering customized solutions to every organization, also keeping in mind the spend analysis and data as an add-on to help save future costs and improve efficiency. It is rightly said that experience can visualize what they want to achieve – NEXSTOC wishes to provide a vast range of products with a fastened service to their clients.</p>

                </div>
              </div>

          <div className="row justify-content-center align-items-center aboutus mt-5">
            <div className="col-12 col-md-12 pb-3">
              
                <img className="rounded-circle float-left mr-3" src={publicUrl+"assets/img/client/user-1.jpg"} alt="co-founder"/>
                  <h5 className="mt-0">Co-Founder</h5>
                  Advancing together is inception, staying together is a headway and working together is a success. Experienced personnel who has worked with varied organizations brings the best to the table. Implying on the same, Mr. Jayshukh Vavia comes with a rich experience of 15+ years in the Office Supplies Industry. He has always worked closely with Mr. Ravriya ensuring to be the backbone of NEXSTOC. Using his experience in the industry, he is very progressive when it comes to broadening the geographical reach of NEXSTOC all over the country. To let a company function smoothly along with the planning process, operational dynamics, and customer service is a vital point. He is quite acquainted with understanding the right customer and providing the best service. He thereby incorporated the founding values of NexStoc as a Nexus of STOC – Speed, Transparency, and Office Supplies & Customized Solutions.
                
            </div>
          </div>

          <div className="row justify-content-center align-items-center aboutus mt-5 pb-5">
            <div className="col-12 col-md-12 pb-3">

                <img className="rounded-circle float-left mr-3" src={publicUrl+"assets/img/client/user-1.jpg"} alt="ceo"/>
                  <h5 className="mt-0">CEO</h5>
                  A company succeeds and grows when it has a profound leader. Experienced personnel believes in growing together and maintaining the standards associated with the company. An individual like Mr. ____ , with extensive knowledge of the industry along with having led numerous multi-national organizations in his career, is a boon to NEXSTOC. He trusts the idea of CUSTOMER IS THE KING. His extent of understanding the subject not only makes him a pioneer of managing the team, daily operations, employee management, and innovative approach for every project undertaken. These capabilities have given birth to some of the most amazing teams. His knowledge has created a healthy percentage of potential sales for the company.

            </div>
          </div>
        </div><br />
        <br />
         <br/>
          {/* our team */}
          <OurTeam />

        </div>

        }
}

export default AboutUs
import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
// import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

class TeamV2 extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        // let imagealt = 'image'
        let data = sectiondata.team
        
        const innerBg = {
          backgroundColor : '#f3f3f6'
        }

    return <>
      <div className="team-area" style={innerBg}>
        <div className="container" >
          <div className="section-title text-center">
            <h2 className="title" style={{color: '#A6A6A6'}}>Features</h2>
          </div>
          <div className="row">
             { data.items.map( ( item, i )=>
              <div key={i } className="col-lg-3 col-sm-4">
                <div className="single-team" style={{height: '240px'}}>
                  <div className="single-feature-inner">
                    <div className="thumb">
                      <img src={publicUrl+item.image} alt="team" />
                    </div>
                    </div>
                    <div className="team-details" style={{padding: '8px 8px 8px 4px'}}>
                      <h6>{ item.name }</h6>
                      <p style={{color: '#4B4C4D',fontSize: '12px', lineHeight: '18px'}}>{ item.feature }</p><br />
                      <p style={{color: '#4B4C4D',marginTop: '20px'}}>
                      <Link to="/features" style={{color : "#18d26e"}}>LEARN MORE</Link>
                      </p>
                    </div>
                   
                  
                </div>
                    
              </div>
             ) }
          </div>
        </div>
      </div>
              </>
        }
}

export default TeamV2
import React, { useEffect, useState } from 'react';

import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import 'firebase/storage';
import swal from 'sweetalert';

const Contact = () => {

	const [name,setName] = useState('');
	const [email,setEmail] = useState('');
	const [phone,setPhone] = useState('');
	const [query,setQuery] = useState('');
	const [msg,setMsg] = useState('');

	const [showNameErr, setShowNameErr] = useState(false)
  const [showPhoneErr, setShowPhoneErr] = useState(false)
  const [showEmailErr, setShowEmailErr] = useState(false)
  

	const db = useFirestore();
	const storage = useFirebaseApp().storage();
  
	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])

	let publicUrl = process.env.PUBLIC_URL+'/'
	const innerStyle={
		backgroundColor : '#f2f6fa'
	}

	// phone validation
	const validatePhone = (mob) => {
		const re = /^[0-9]{10}$/;
		return re.test(mob);
	  }

	  // email validation
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(name,phone,msg,query,email);
		if(name === '') {
			setShowNameErr(true)
		  }else if(email === '' || !validateEmail(email)) {
			setShowEmailErr(true)
		  }else if(phone === '' || !validatePhone(phone)) {
			setShowPhoneErr(true)
		  }else {
			  
				db.collection("Contact")
				.add({
					name,
					phone,
					email,
					msg,
					query,
					date : new Date()
				  })
				.then(function(docRef) {
					console.log("Document written with ID: ", docRef.id);
					var washingtonRef = db.collection("Contact").doc(docRef.id);
	  
				  // Set the "capital" field of the city 'DC'
				  return washingtonRef.update({
					  id: docRef.id
				  })
				  .then(() => {
					  console.log("Document successfully updated!");
					  swal("Done", "Thank you. We'll contact you soon.", "success");

					//   swal("Success!!!", "Blog Added", "success");
					setName('');
					setPhone('');
					setMsg('');
					setQuery('');
					setEmail('');
					setShowEmailErr(false);
					setShowNameErr(false);
					setShowPhoneErr(false);
				  })
				//   .then(() => history.push('/view-blog'))
				  .catch((error) => {
					  // The document probably doesn't exist.
					  console.error("Error updating document: ", error);
				  });
	  
				})
				.catch(function(error) {
					console.error("Error adding document: ", error);
				});
				
			}
			
	}
    return <div style={innerStyle}>
        <div className="contact-area pd-top-120 pd-bottom-65">
		  <div className="container text-center">
			  <h3>CONTACT US</h3>
			  <p>If you have any problems,suggestions and feedback then please feel free to contact with us. Choose our communication soruces. We will love to hear from you.
</p>
		    <div className="row">
		      <div className="col-lg-8">
		        <div className="contact-page-map">
		          {/* <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d60021.82409444856!2d-122.40118071595978!3d37.7546723469594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1577786376747!5m2!1sen!2sbd" 
				  style={{border: 0}} allowFullScreen /> */}
				  <img src={publicUrl+"assets/img/gallery/support_2.webp"} />
		        </div>
		      </div>
		      <div className="col-lg-4">
		        <form className="contact-form-wrap contact-form-bg text-left"  onSubmit={handleSubmit}>
		          <h4>Contact Now</h4>
		          <div className="rld-single-input">
		            <input type="text" placeholder="Name" value={name}
					onChange={(e => {setName(e.target.value)})}/><br/>
					{showNameErr ? <span style={{color:'red', fontWeight:'bold',fontSize:'smaller', textAlign:'left'}}>Please Enter Your Name</span>:null}
              
		          </div>
		          <div className="rld-single-input">
		            <input type="text" placeholder="Phone" value={phone}
					onChange={(e => {setPhone(e.target.value)})}/> <br/>
					{showPhoneErr ? <span style={{color:'red', fontWeight:'bold',fontSize:'smaller', textAlign:'left'}}>Please Enter Valid Mobile Number</span>:null}

		          </div>
		          <div className="rld-single-input">
		            <input type="text" placeholder="Email" value={email}
					onChange={(e => {setEmail(e.target.value)})} /><br/>
					{showEmailErr ? <span style={{color:'red', fontWeight:'bold',fontSize:'smaller', textAlign:'left'}}>Please Enter Valid Email</span>:null}

		          </div>
		          <div className="rld-single-input">
		            <select className="" value={query}
					onChange={(e => {setQuery(e.target.value)})}>
						<option value="Select..."></option>
						<option value="I want to book demo">I want to book demo</option>
						<option value="I have a question about pricing">I have a question about pricing</option>
						<option value="I have a question about billing">I have a question about billing</option>
						<option value="Other">Other</option>
					</select>
		          </div>
		          <div className="rld-single-input">
		            <textarea rows={10} placeholder="Message" value={msg}
					onChange={(e => {setMsg(e.target.value)})}/>
		          </div>
		          <div className="btn-wrap text-center">
		            <button className="btn btn-yellow">Submit</button>
		          </div>
		        </form>
		      </div>
		    </div>
		    <div className="row pd-top-92">
		      <div className="col-xl-4 col-sm-6 ">
		        <div className="single-contact-info p-3">
		          <h5>Sales Support</h5>
				  <p>Got a question? We're here to help!</p><br/>
				  <a href="tel:12345688" target="_blank"><i className="fa fa-phone" />+12390394903</a>


		        </div>
		      </div>
		      <div className="col-xl-4 col-sm-6">
		        <div className="single-contact-info p-3">
		          <h5>Customer Support</h5>
				  <p>We define modern customer support as the act of providing timely, empathetic help that keeps customers’ needs at the forefront of every interaction.</p>
		          <a href="tel:12345688" target="_blank"><i className="fa fa-phone" />+12390394903</a>

		        </div>
		      </div>
		      <div className="col-xl-4 col-sm-6">
		        <div className="single-contact-info p-3">
		          <h5>Address</h5>
		          <p>1899 Cemetery Street,MEDFORD - 1452451 New York</p>
		          <a href="tel:12345688" target="_blank"><i className="fa fa-phone" />+12390394903</a>
		          <br/><a href="support@theironnetwork.org" target="_blank">support@theironnetwork.org</a>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
    </div>
}

export default Contact


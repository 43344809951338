import React, { Component } from 'react';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import Footer from './components/global-components/footer-v2';
import Navbar from './components/global-components/navbar';
import HomeV3 from './components/home-v3';
import AboutUs from './components/section-components/about-us';
import BlogDetails from './components/section-components/BlogDetails';
import Blogs from './components/section-components/Blogs';
import Career from './components/section-components/Career';
import Contact from './components/section-components/contact';
import BlogListPage from './components/BlogListPage';
import CaseStudyDetails from './components/section-components/CaseStudyDetails';
import Features from './components/section-components/Features';

class Main extends Component {
    render() {
        return(
            <Router>
                <HashRouter basename="/">
                <Navbar />
                <Switch>
                     <Route exact path="/" component={HomeV3} />
                     <Route path="/about" component={AboutUs} />
                     <Route path="/blogs" component={BlogListPage} />
                     <Route path="/blog-details/:id" component={BlogDetails} />
                     <Route path="/case-study/:id" component={CaseStudyDetails} />
                     <Route path="/contact-us" component={Contact} />
                     <Route path="/career" component={Career} />
                     <Route path="/features" component={Features} />
                </Switch>
                <Footer />
                </HashRouter>
            </Router>
        )
    }
}

export default Main;

// ReactDOM.render(<Root />, document.getElementById('realdeal'));

import React, { useEffect } from 'react';
import Navbar from './global-components/navbar';
import Bannerv3 from './section-components/banner-v3';
import FeaturedProperties from './section-components/featured-properties';
import Dream from './section-components/dream';
import CaseStudy from './section-components/CaseStudy';
import TeamV2 from './section-components/team-v2';
import Testimonials from './section-components/Testimonials';
import OurPartner from './section-components/our-partner';
import Footer from './global-components/footer-v2';
import { useLocation } from 'react-router';

const Home_V1 = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    const location = useLocation();
    useEffect(()=> {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {
        window.scrollTo({top:0,left:0, behavior: "smooth"})
        }
}, [location,])

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //   }, [])
    return <div>
        {/* <Navbar /> */}
        <Bannerv3 />
        <OurPartner />

        {/* <div id="features" className="pt-70 _21-wave-bg-top "><img src={publicUrl+'assets/img/banner/section-bg.png'} className="feature-bg-top" /></div> */}
        <TeamV2 Customclass="pd-top-90" />
        <div className="pt-70 _21-wave-bg-btm"><img src={publicUrl+'assets/img/banner/section-bg-down.png'} className="feature-bg-top" /></div>


        <div id="process"><Dream /></div>

        <div id="testimonial" className="pt-90 _21-wave-bg-top"><img src={publicUrl+'assets/img/banner/section-bg.png'} className="feature-bg-top" /></div>
        <Testimonials />
        <div  className="pt-70 _21-wave-bg-btm"><img src={publicUrl+'assets/img/banner/section-bg-down.png'} className="feature-bg-top" /></div>

        <div id="casestudy"><CaseStudy /></div>

        {/* <Footer /> */}
    </div>
}

export default Home_V1


import React, { Component } from 'react';
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import { Link } from 'react-router-dom';
import blogdata from '../../data/blogdata.json';
import PostList from './post-list';

class PopularPost extends Component {

  constructor(props) {  
    super(props);
      this.state = { 
        searchValue : "",
        searchResult : [],
        data : this.props.blogsList
      };
  }

  componentDidMount() {
    if ($('.news-search-btn').length){
      $(".news-search-btn").on('click', function(){
          $(".news-search-box").toggleClass("news-search-box-show", "linear");
      });
      $('body').on('click', function(event) {
          if (!$(event.target).closest('.news-search-btn').length && !$(event.target).closest('.news-search-box').length) {
              $('.news-search-box').removeClass('news-search-box-show');
          }
      });
  }

  }

   //  Search in textbox
    searchBlogResult = (val) => {
    return this.props.blogsList.filter(data => data.title.toLowerCase().includes(val.toLowerCase()))
    
  }
 search = (e) => {
   this.setState({searchValue : e.target.value , searchResult : this.searchBlogResult(e.target.value)})
    // setSearchValue(e.target.value)
    console.log(e.target.value)

    // setSearchResult(searchBlogResult(e.target.value))
}


  render() {

      let publicUrl = process.env.PUBLIC_URL+'/'
      let imagealt = 'image'
      let data = this.props.blogsList

      const sliderOption= {
        options:{
          loop: true,
          autoplay : true,
          dots:false,
          nav:false,
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:3
              },
              1000:{
                  items:4
              }
          }
      },
      }
   
    return (
      <div>
         <div className="popular-post-area">
          <div className="container">
            <div className="post-and-search">
              <div className="news-search-btn">
                <i className="fa fa-search" />
              </div>
              <div className="news-search-box">
                <input type="text" placeholder="Search Blog" onChange={e => {this.search(e)}} />
                <button><i className="fa fa-search" /></button>
              </div>
              <h6 className="mb-2 popular-post-title">Popular Blogs</h6>
              {/* <div className="popular-post-slider"> */}
              <OwlCarousel className='owl-theme text-center client-slide' 
                  {...sliderOption.options}
                   >
                { this.props.blogsList.map( ( item, i )=>
                    <div key={ i } className="item m-1">
                    <Link to={{
                        pathname: `/blog-details/${item.id}`,
                        state: {blogDetail : item }
                        }} className="media single-popular-post">
                      <div className="media-left" >
                      <img src={ item.downloadURL} alt={ imagealt } style={{width:'70px',height:"50px"}}/>
                      </div>
                      <div className="media-body">
                        <h6>{ item.title }</h6>
                        {/* <span>{ item.date }</span> */}
                      </div>
                    </Link>
                  </div>
                 ) }
                
                </OwlCarousel>
            </div>
          </div>
        </div>
        <PostList searchResult = {this.state.searchResult } 
        searchValue={this.state.searchValue}
        blogsList={this.props.blogsList}/>
      </div>
    )
  }
}

export default PopularPost;


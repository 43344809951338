import React from 'react';
const Abbreviation = () => {
	let publicUrl = process.env.PUBLIC_URL+'/'
	    const innerBg = {
          backgroundColor : '#f3f3f6'
        }


    return (
        <div>
        <div className="pt-50 _21-wave-bg-top"><img src={publicUrl+'assets/img/banner/section-bg.png'} className="feature-bg-top" /></div>
          
          <div className="row justify-content-center align-items-center" style={innerBg}>
          <div className="col-12 col-md-12" style={{textAlign:"center", fontSize:"20px"}}> 
           <h3>Abbreviation</h3><br/>
           {/* <p>An abbreviation with NexStoc could be created(Sample as below) :</p> */}
          </div>
            
            <ul style={{fontSize:"20px"}}>
                <li>N:- nurture </li>
                <li>E:- experience</li>
                <li>X:- excel </li>
                <li>S:- synergy</li>
                <li>T:- trial and error </li>
                <li>O:- organized</li>
                <li>C:- creativity</li>         
            </ul>
            
          </div>
          <div className="pt-50 _21-wave-bg-btm"><img src={publicUrl+'assets/img/banner/section-bg-down.png'} className="feature-bg-top" /></div>

        </div>
    )
}

export default Abbreviation;
import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
// import parse from 'html-react-parser';

class Features extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        // let imagealt = 'image'
        let data = sectiondata.features

        
        const innerBg = {
          backgroundColor : '#f3f3f6'
        }

    return <>
      <div className="team-area pd-top-40 pd-bottom-90" style={innerBg}>
        <div className="container" >
          <div className="section-title text-center">
            <h2 className="title">{ data.titlev2 }</h2>
          </div>
          <div className="row p-2">
             { data.items.map( ( item, i )=>
              <div key={i } className="col-lg-4 col-sm-6">
                <div className="single-team p-3" style={{minHeight : '260px'}}>
                  <div className="single-feature-inner">
                    <div className="thumb">
                      <img src={publicUrl+item.image} alt="team" />
                    </div>
                    <div className="team-details">
                      <h6>{ item.name }</h6>
                      
                    </div>
                    </div>
                    <span>{item.feature}</span>
                </div>
                    
              </div>
             ) }
          </div>


{/* 
         
          <div className="row p-3 justify-content-center align-items-center">
             { data.items.map( ( item, i )=>
              <div key={i } className="col-lg-8 col-sm-6 p-3">
                <div className="single-team">
                  <div className="single-feature-inner">
                    <div className="thumb">
                      <img src={publicUrl+item.image} alt="team" />
                    </div>
                    <div className="team-details">
                      <h6>{ item.name }</h6>
                    </div>
                    </div>
                    <span>{item.feature}</span>
                  
                </div>
                    
              </div>
             ) }
          </div> */}
          
        </div>
      </div>
              </>
        }
}

export default Features
import React, { useEffect, useState } from 'react';


import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import 'firebase/storage';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';

function CaseStudy() {
  const [blogs, setBlogs] = useState([])
  const db = useFirestore();
  
  useEffect(() => {
      fetchBlogs();
  }, [])

  const fetchBlogs=async()=>{
      const response=db.collection('CaseStudy');
      const data=await response.get();
      data.docs.forEach(item=>{
       setBlogs(blogs => [...blogs,item.data()])
       console.log(item.data())
      })
  }




        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.popularproperty



        const sliderOption= {
          options:{
            loop: true,
            autoplay : true,
            dots:false,
            nav:true,
            autoplayHoverPause : true,
            smartSpeed: 1500,
            navText : ['<i class="fa fa-angle-left m-2"></i>','<i class="fa fa-angle-right m-2"></i>'],
            responsive:{
              0:{
                  items:1
              },
              576:{
                  items:2
              },
              768: {
                  items: 3,
              },
              992: {
                  items: 3,
              }
          }
        },
        }
  
    return <div className="properties-area pd-top-90">
          <div className="container">
            <div className="section-title">
              <h2 className="title" style={{textAlign:"center"}}>CASE STUDY</h2>
            </div>
            <div className="row align-items-center justify-content-center">
            <OwlCarousel className='owl-theme ' 
            
                  {...sliderOption.options}
                   >
              
            { blogs.map( ( item, i )=>
            <div key={ item.id } className="p-3">
                <div  className="single-feature">
                  <div className="thumb">
                    <img src={ item.downloadURL1 } alt="img" />
                  </div>
                  <div className="details">
                    {/* <a href="#" className="feature-logo">
                      <img src={ publicUrl+item.icon } alt={ imagealt } />
                    </a> */}
                    <h6 className="title readeal-top" >
                    <Link to={{
                            pathname: `/case-study/${item.id}`,
                            state: {blogDetail : item }
                            }}  >{ item.CSTitle }</Link>
                    </h6>
                    <p>{item.CSDesc}</p>
                    <ul className="contact-list">
                        <li className="readeal-top"><i className = "fa fa-user"/> {item.CSAuthor}</li>
                        
                    </ul>
                  </div>
                </div>
                </div>
              ) }
            </OwlCarousel>
            </div>
          </div>
        </div>
        
}

export default CaseStudy
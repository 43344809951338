import React from "react";
import Firebase from "./backend/firebase";
import Main from './Main';

function App() {
  return (
    <Firebase>
      <Main />
    </Firebase>
  );
}

export default App;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import blogdata from '../../data/blogdata.json';

class PostList extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    let data = this.props.blogsList
    return (
      <div>
         <div className="property-news-area pd-top-100 pd-bottom-70">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                { this.props.searchValue != "" && this.props.searchResult.length > 0 ? (
                  <>
                    { this.props.searchResult.map( ( item, i )=>
                      <div key={ i } className="col-lg-4 col-10">
                      <div className="single-feature">
                        <div className="thumb">
                            <img src={item.downloadURL} alt={ imagealt } />
                          </div>
                          <div className="details">
                            <h4><Link to={{
                            pathname: `/blog-details/${item.id}`,
                            state: {blogDetail : item }
                            }}  >{ item.title }</Link></h4>
                            <p>{ item.short_desc }</p>
                            <div className="author">
                              {/* <img src={publicUrl+item.authorimage} alt={ imagealt } /> */}
                              <i className="fa fa-profile"></i> <span>By { item.author }</span>
                              {/* <span className="date">{ item.date }</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                     ) }
                    </>
                ) : this.props.searchValue !== '' ? (
                  <>
                <div className="row pb-3 justify-content-center align-items-center text-center" style={{textAlign:"center"}}>
                <div className="col-12" style={{textAlign:"center"}}>
               Search Not Found!</div>
 
            </div>
            </>
                ) :(<>
                  { data.map( ( item, i )=>
                  <div key={ i } className="col-lg-4 col-10">
                    <div className="single-feature">
                      <div className="thumb">
                        <img src={item.downloadURL} alt={ imagealt } />
                      </div>
                      <div className="details">
                        <h4><Link to={{
                        pathname: `/blog-details/${item.id}`,
                        state: {blogDetail : item }
                        }}  >{ item.title }</Link></h4>
                        <p>{ item.short_desc }</p>
                        <div className="author">
                          {/* <img src={publicUrl+item.authorimage} alt={ imagealt } /> */}
                          <i className="fa fa-user"></i> <span> By { item.author }</span>
                          {/* <span className="date">{ item.date }</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                 ) }
                
                  </>
                )}
                
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default PostList;

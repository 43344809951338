import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


class OurPartner extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.partner


        const sliderOption= {
          options:{
            loop: true,
            autoplay : true,
            dots:false,
            nav:false,
            smartSpeed: 1000,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:3
                },
                1000:{
                    items:4
                }
            }
        },
        }
  
    return <div className="client-area pd-top-40 pd-bottom-100">
            <div className="container">
              <div className="section-title text-center">
                <h2 className="title" style={{color: '#A6A6A6', fontSize: '40px'}}>Few of our <span style={{color: '#303030', backgroundImage: 'url("../../assets/img/others/clients_underline.svg")',
          backgroundPosition: '0 1.06em',
          backgroundRepeat: 'repeat-x',}} >clients</span></h2>
              </div>
              {/* <div className="client-slider"> */}
              <OwlCarousel className='owl-theme text-center client-slide' 
                  {...sliderOption.options}
                   >
               { data.items.map( ( item, i )=>
                  <div key={ i } className="item p-5">
                  <div className="thumb">
                    <img src={ publicUrl+item.image } alt="client" style={{width:"120px"}}/>
                  </div>
                </div>
                ) }
                
              </OwlCarousel>
            </div>
          </div>
        }
}

export default OurPartner
import React, { useState } from "react";

import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import swal from "sweetalert";

const ApplyJob = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState("");

  const [showNameErr, setShowNameErr] = useState(false);
  const [showPhoneErr, setShowPhoneErr] = useState(false);
  const [showEmailErr, setShowEmailErr] = useState(false);
  const [showResumeErr, setShowResumeErr] = useState(false);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();

  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const innerStyle = {
    backgroundColor: "#f2f6fa",
  };

  //Upload Documents
  const handleFiles = (e) => {
    setFormData(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name, phone, formData, email);
    if (name === "") {
      setShowNameErr(true);
    } else if (email === "" || !validateEmail(email)) {
      setShowEmailErr(true);
    } else if (phone === "" || !validatePhone(phone)) {
      setShowPhoneErr(true);
    } else if (formData === "") {
      setShowResumeErr(true);
    } else {
      const uploadTask = storage.ref(`/careers/${formData.name}`).put(formData);
      const downloadURL = await (await uploadTask).ref.getDownloadURL();

      db.collection("Careers")
        .doc(props.doc)
        .collection("Applications")
        .add({
          name,
          phone,
          email,
          resume: downloadURL,
        })
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id);

          console.log("Document successfully updated!");
          swal(
            "Applied",
            "Thank you for applying. We'll contact you soon.",
            "success"
          );
          setName("");
          setPhone("");
          setEmail("");
          setFormData("");
          setShowEmailErr(false);
          setShowNameErr(false);
          setShowPhoneErr(false);
          setShowResumeErr(false);
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
  };

  return (
    <div style={innerStyle}>
      <div className="contact-area pd-top-50 pd-bottom-65">
        <div className="container ">
          <div className="row">
            <div className="col-lg-10">
              <form
                className="contact-form-wrap contact-form-bg"
                onSubmit={handleSubmit}
              >
                <h6 className="text-center">Apply for this position</h6>
                <div className="rld-single-input">
                  <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <br />
                  {showNameErr ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "smaller",
                        textAlign: "left",
                      }}
                    >
                      Please Enter Your Name
                    </span>
                  ) : null}
                </div>
                <div className="rld-single-input">
                  <input
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />{" "}
                  <br />
                  {showPhoneErr ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "smaller",
                        textAlign: "left",
                      }}
                    >
                      Please Enter Valid Mobile Number
                    </span>
                  ) : null}
                </div>
                <div className="rld-single-input">
                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <br />
                  {showEmailErr ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "smaller",
                        textAlign: "left",
                      }}
                    >
                      Please Enter Valid Email
                    </span>
                  ) : null}
                </div>
                <br />
                <div className="display-flex mr-2">
                  <label>Upload Resume<span style={{color:'red',fontWeight:'bold'}}>*</span>:</label>&nbsp;
                  <input
                    type="file"
                    onChange={(e) => handleFiles(e, "gstCerti")}
                  />
                </div>
                  <br />
                  {showResumeErr ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "smaller",
                        textAlign: "left",
                      }}
                    >
                      Please Upload Resume
                    </span>
                  ) : null}
                <div className="btn-wrap text-center">
                  <button className="btn btn-yellow">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyJob;

import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';

class Page_header extends Component {
    
    render() {

        let HeaderTitle = this.props.headerTitle;
        let publicUrl = process.env.PUBLIC_URL+'/'
        let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle
        const inlineStyle = {
            backgroundImage: 'url('+publicUrl+'assets/img/banner/blog-main-banner.jpg)',
            opacity : '0.6'
        }
        console.log(this.props.location.pathname)
        return (
            <div className="breadcrumb-area jarallax pd-top-120" style= { inlineStyle }>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="breadcrumb-inner">
                      <h1 className="page-title text-center">{ HeaderTitle }</h1>
                      <ul className="page-list mt-5 font-weight-bold">
                      <li><Link to="/">Home</Link></li>
                        {this.props.page === 'case-study' ? <li><Link to="/">Case Study</Link></li>
                        : <li><Link to="/blogs">Blogs</Link></li>
                        }
                        <li>{ Subheader }</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
    }
}


export default withRouter(Page_header)
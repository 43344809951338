import React, { useEffect, useState } from 'react';

import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import 'firebase/storage';

import PageHeader from './global-components/page-header';
import PopularPost from './blog-components/popular-post';
import PostList from './blog-components/post-list';

const BlogListPage = () => {
    const [blogs, setBlogs] = useState([])
    const db = useFirestore();
    
    useEffect(() => {
        window.scrollTo(0,0);
        fetchBlogs();
    }, [])

    const fetchBlogs=async()=>{
        const response=db.collection('Blogs');
        const data=await response.get();
        data.docs.forEach(item=>{
         setBlogs(blogs => [...blogs,item.data()])
         console.log(item.data())
        })
    }

    return <div>
        <PageHeader headertitle="Blogs" subheader="Blogs List" />
        <PopularPost blogsList={blogs} />
    </div>
}

export default BlogListPage

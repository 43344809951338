import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollLink} from 'react-scroll'

class NavbarHeader extends Component {

  
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <div>
                <div className="navbar-area">
                <nav className="navbar navbar-area navbar-expand-lg">
                  <div className="container nav-container">
                    <div className="responsive-mobile-menu">
                      <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="icon-left" />
                        <span className="icon-right" />
                      </button>
                    </div>
                    <div className="logo readeal-top">
                      <Link to="/"><img src={publicUrl+"Nexstoc Final Logo.png"} alt="logo" className="logo"/></Link>
                    </div>
                    {/* <div className="nav-right-part nav-right-part-mobile">
                      <Link className="btn btn-yellow" to="/add-property">ADD LISTING <span className="right"><i className="la la-plus" /></span></Link>
                    </div> */}
                    <div className="collapse navbar-collapse" id="realdeal_main_menu">
                      <ul className="navbar-nav menu-open readeal-top">
                        <li className="current-menu-item">
                          <Link to="/">Home</Link>               
                        </li>
                        <li className="current-menu-item">
                          <Link to="/">Products</Link>
                          <ul className="sub-menu">
                             {/* <li><Link to="/">Overview</Link></li> */}
                            {/* <li><Link to="/">Accessories</Link></li> */}
                          </ul>
                        </li>
                        <li className="menu-item-has-children">
                          <a >Platform</a>
                          <ul className="sub-menu">
                            <li><Link to="/#features" >Features</Link></li>
                            <li><Link to="/#process" >Process</Link></li>
                          </ul>
                        </li>
                        <li className="menu-item-has-children">
                          <a >Customers</a>
                          <ul className="sub-menu">
                            {/* <li><Link to="/">Industries</Link></li> */}
                            <li><Link to="/#testimonial" >Testimonials</Link></li>
                            <li><Link to="/#casestudy" >Case Study</Link></li>
                          </ul>
                        </li>
                        
                        <li className="menu-item-has-children">
                          <a href="#">Company</a>
                          <ul className="sub-menu">
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/blogs">Blogs</Link></li>
                            <li><Link to="/career">Career</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div className="nav-right-part nav-right-part-desktop readeal-top m-2">
                      <Link className="btn btn-yellow" to="/">Login</Link>
                    </div>

                    <div className="nav-right-part nav-right-part-desktop readeal-top m-2">
                       <p style={{ color:'black'}}>1-888-463-5254</p>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
        )
    }
}


export default NavbarHeader
import React, { Component, useEffect, useState } from 'react';

import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import 'firebase/storage';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import sectiondata from '../../data/sections.json';
// import parse from 'html-react-parser';

function Testimonials() {

  const [blogs, setBlogs] = useState([])
    const db = useFirestore();
    
    useEffect(() => {
        fetchBlogs();
    }, [])

    const fetchBlogs=async()=>{
        const response=db.collection('Testimonials');
        const data=await response.get();
        data.docs.forEach(item=>{
         setBlogs(blogs => [...blogs,item.data()])
         console.log(item.data())
        })
    }


  let publicUrl = process.env.PUBLIC_URL+'/'
  // let imagealt = 'image'
  let data = sectiondata.client
  let PaddingTop =  'pd-top-40'
  let PaddingBottom = 'pd-bottom-40'

  const innerBg = {
    backgroundColor : '#f3f3f6'
  }
    return <div className={"client-area "+PaddingTop+' '+PaddingBottom} style={innerBg}>
          <div className="container">
            <div className="section-title text-center">
              <h2 className="title" style={{color: '#303030', fontWeight: '700'}}>Testimonials</h2>
            </div>
            <div className="client-review-img">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10">
                  {/* <div className="client-slider-2 text-center"> */}
                  <OwlCarousel className='owl-theme text-center ' 
                  loop 
                  margin={10} 
                  items={1}
                  center={true}
                  dots={false}
                  autoplay={true}
                  smartSpeed={1000}
                   nav
                   >
                  { blogs.map( (item, i)=>
                      <div key={ item.id } className="item">
                        <div className="single-client-review" style={{backgroundColor: '#fff', width:'100%', height: '200px', boxShadow: '4px 10px 40px rgba(48, 48, 48, 0.1)',
borderRadius: '21.8774px'}}>
                          <div className="testimonial-icon">
                            {/* <img src={publicUrl+item.image} alt={ imagealt } className="rounded-circle"/> */}
                            <i className="fa fa-quote-left" />
                          </div>
                          <div className="review-details pt-5">
                            <p>{ item.TestimonialTitle }</p>
                            <h4>{ item.TestimonialAuthor }</h4>
                            <p>{ item.CompanyPosition }</p>
                          </div>
                        </div>
                    </div>
                   ) }
                </OwlCarousel>

                  </div>
              </div>
            </div>
          </div>
        </div>

    
}

export default Testimonials
import React from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement
  } from "react-vertical-timeline-component";
  import "react-vertical-timeline-component/style.min.css";
  import sectiondata from '../../data/sections.json';

  function WhyNexStoc() {
    let data = sectiondata.whyNexStoc
    let publicUrl = process.env.PUBLIC_URL+'/'
	    const innerBg = {
          backgroundColor : '#f3f3f6'
        }
    return (
      <div
        className="App"
        style={{ background: "", fontFamily: "Trebuchet Ms" }}
      >
        <div className="pt-50 _21-wave-bg-top"><img src={publicUrl+'assets/img/banner/section-bg.png'} className="feature-bg-top" /></div>
        <div style={innerBg}>
        <h3 className="text-center">Why NexStoc</h3>
        <VerticalTimeline>
          {data.map((event,i) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: "#18d26e", color: "#fff"}}
              position = "center"
              key={i}
            >
              <h6
                className="vertical-timeline-element-title"
                dangerouslySetInnerHTML={{ __html: event.title }}
              />
              <p>{event.subtitle}</p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
      <div className="pt-50 _21-wave-bg-btm"><img src={publicUrl+'assets/img/banner/section-bg-down.png'} className="feature-bg-top" /></div>

      </div>
    );
  }

  export default WhyNexStoc;
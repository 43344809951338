import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import 'firebase/storage';
import {useHistory} from 'react-router-dom';
import ApplyJob from './ApplyJob';

const ViewCareer = () => {
    const [blogs, setBlogs] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [searchResult, setSearchResult] = useState([])
    const [openJob, setOpenJob] = useState({})
    const [showForm, setShowForm] = useState({})

    const db = useFirestore();
    const storage = useFirebaseApp().storage();
    let history = useHistory();

    useEffect(() => {
        fetchBlogs();
    }, [])


    const useStyles = makeStyles((theme) => ({
        button: {
        margin: theme.spacing(1),
        },
    }));
    const classes = useStyles();


    const fetchBlogs=async()=>{
        const response=db.collection('Careers');
        const data=await response.get();
        data.docs.forEach(item=>{
        let jobs = openJob;
        jobs[item.data().id] = false
        //  let key = item.data().id;
        //  openJob[key] = false;
         setBlogs(blogs => [...blogs,item.data()])
         setOpenJob(jobs)
         setShowForm(jobs)
         console.log(item.data())
        })
    }

    //  View selected blog btn click
    const handleViewBtn = (blog_detail) => {
        console.log(blog_detail);
        history.push('/blog-detail',blog_detail)
    }

    //  Search in textbox
    const searchBlogResult = (val) => {
        return blogs.filter(data => data.title.toLowerCase().includes(val.toLowerCase()))
        
    }
    const search = (e) => {
        setSearchValue(e.target.value)
        console.log(e.target.value)
  
        setSearchResult(searchBlogResult(e.target.value))
    }

    // handle view detail click
    const handleViewDetail = (id) => {
        const clickedJobs = openJob;

        clickedJobs[id] = openJob.hasOwnProperty(id) ? !openJob[id] : true;
        setOpenJob({...clickedJobs});
        console.log(openJob)
    }

    // handle Apply click
    const handleApplyJob = (id) => {
        const clickedJobs = showForm;

        clickedJobs[id] = showForm.hasOwnProperty(id) ? !showForm[id] : true;
        setShowForm({...clickedJobs});
        console.log(showForm)
    }

  return (
        <div className="container mt-5 pt-5">
            <h3 className="text-center">LET’S DO SOMETHING GREAT TOGETHER</h3>
            <div className="row pb-3 justify-content-center mt-5"><input type="text"
             className="col-10 col-md-6 search-box" 
            placeholder = "Search Job" onChange={e => {search(e)}}/></div>
            <div className="row justify-content-center mt-2">
            {
                
                blogs.length > 0 && searchValue === '' ? blogs.map((blog)=>{
                return(
       
                <div key={blog.id} className="blog-card col-lg-8">
                    <div>
                        <div>
                            <div>
                                <h6>{blog.title}</h6>
                            </div>
                            <p className="author-name">Location : {blog.location}</p>
                            {openJob[blog.id] ? null : (<p style={{color:"#18d26e", cursor:"pointer"}} 
                            onClick={() => handleViewDetail(blog.id)}>
                                <u>+ View Detail</u></p>)  }

                            {openJob && openJob[blog.id] ? ( <div>
                                <p>Qualification: {blog.qual}</p>
                                <p>Description: {blog.jobDesc}</p>
                                <p className="view-edit" style={{display:'flex ', flexDirection:'row', margin:'5px'}}>
                                <button className="btn btn-yellow btn-small"
                                 onClick={() => handleApplyJob(blog.id)}>
                                     {showForm && showForm[blog.id] ? "Cancel" : "Apply"}</button>
                                
                                <span style={{color:"#18d26e", cursor:"pointer", margin:"8px"}} 
                                onClick={() => handleViewDetail(blog.id)}> 
                                <u>- Hide Detail</u></span>
                                </p><br />
                                {showForm && showForm[blog.id] ? <ApplyJob doc={blog.id}/> : null}
                                </div>
                            ) : <div> </div>}
                        </div>
                    </div>
                <hr />
                </div>
            )}
            ) : (
                <>
                    {searchResult.length > 0 ? searchResult.map(blog => {

                    return(
       
                        
                        <div key={blog.id} className="blog-card col-lg-8">
                        <div>
                            <div>
                                <div>
                                    <h6>{blog.title}</h6>
                                </div>
                                <p className="author-name">Location : {blog.location}</p>
                                {openJob[blog.id] ? null : (<p style={{color:"#18d26e", cursor:"pointer"}} 
                            onClick={() => handleViewDetail(blog.id)}>
                                <u>+ View Detail</u></p>)  }

                                {openJob && openJob[blog.id] ? ( <div>
                                <p>Qualification: {blog.qual}</p>
                                <p>Description: {blog.jobDesc}</p>
                                <p className="view-edit" style={{display:'flex ', flexDirection:'row', margin:'5px'}}>
                                <button className="btn btn-yellow btn-small"
                                 onClick={() => handleApplyJob(blog.id)}>
                                     {showForm && showForm[blog.id] ? "Cancel" : "Apply"}</button>
                                
                                <span style={{color:"#18d26e", cursor:"pointer", margin:"8px"}} 
                                onClick={() => handleViewDetail(blog.id)}> 
                                <u>- Hide Detail</u></span>
                                </p><br />
                                {showForm && showForm[blog.id] ? <ApplyJob doc={blog.id}/> : null}
                                </div>
                            ) : <div> </div>}
                            </div>
                        </div>
                    <hr />
                    </div>
                  )
                }) : (
                  <>
                  {searchValue !== '' ? (

                  <div className="row pb-3 justify-content-center">
                  <div>
                 Search Not Found!</div>
   
              </div>
                  ) : <div></div>
                  }
                
                </>
                )
                }
                </>
            )}      
        </div>
        </div>
  );
};

export default ViewCareer;
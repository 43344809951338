import { FirebaseAppProvider } from "reactfire";

const firebaseConfig = {
  /* Add your config from the Firebase Console */
  apiKey: "AIzaSyDdCeStVIQLfJwkLWrY62iU53qZKmI5xuQ",
  authDomain: "nexstoc-8fb29.firebaseapp.com",
  projectId: "nexstoc-8fb29",
  storageBucket: "nexstoc-8fb29.appspot.com",
  messagingSenderId: "7424837949",
  appId: "1:7424837949:web:943846bebfdf726c111d54",
  measurementId: "G-1ENMGPJDQN",
};

const Firebase = (props) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      {props.children}
    </FirebaseAppProvider>
  );
};

export default Firebase;

import React from 'react';
import sectiondata from '../../data/sections.json';

const OurTeam = () => {
	let publicUrl = process.env.PUBLIC_URL+'/'
	let data = sectiondata.ourteam
        const innerBg = {
          backgroundColor : '#f3f3f6'
        }


    return (
        <div>
                      {/* our team */}
          <div className="pt-50 _21-wave-bg-top"><img src={publicUrl+'assets/img/banner/section-bg.png'} className="feature-bg-top" /></div>
          
          <div className="row justify-content-center align-items-center p-5" style={innerBg}>
          <div className="col-12 col-md-12" style={{textAlign:"center"}}>  <h3>Our Team</h3>
          </div>
            {data.team.map((team,i) => (

            <div className="col-10 col-md-3 m-2 p-3 aboutus" key={i}>
              <h4>{team.name}</h4>
              <p>{team.designation}</p>
              <ul className="social-icon">
                         { team.social.map( ( item, i )=>
                            <li key={i}>
                              <a href={ item.url } target="_blank"><i className={ item.icon } /></a>
                            </li>
                          ) }
                        </ul>
            </div>
            ))}
          </div>
          <div className="pt-50 _21-wave-bg-btm"><img src={publicUrl+'assets/img/banner/section-bg-down.png'} className="feature-bg-top" /></div>

        </div>
    )
}

export default OurTeam;
import React, { useEffect, useRef } from 'react';
import {Link} from 'react-scroll'
import Abbreviation from './Abbreviation';
import OurTeam from './OurTeam';
import ViewCareer from './ViewCareer';
import WhyNexStoc from './WhyNexStoc';

const Career = () => {
	let publicUrl = process.env.PUBLIC_URL+'/'
	const innerStyle={
		backgroundColor : '#f2f6fa'
	}
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return <div style={innerStyle}>
        <div className="pd-top-120 pd-bottom-65">

                <h3 className="text-center">THE DOOR OF OPPORTUNITIES</h3>
                <div className="row pt-5">
                    <div className="col-lg-8 p-5">
                        <h5>Together everyone achieves more</h5><br/>
                        <p>NexStoc, a space where people are encouraged to chase their skilful imagination and are inspired to execute those healthy decisions. We ensure that our people's potential is performance-driven. We create an environment motivating them to work as a team for their personal and professional growth</p><br/>
                        <Link to="jobs" spy={true} smooth={true} className="btn btn-yellow" >SEE OUR JOBS</Link>
                    </div>
		            <div className="col-lg-4"><img src={publicUrl+'assets/img/gallery/careerwave.webp'} /></div>
                </div>

                <WhyNexStoc /><br />
                <div id="jobs"><ViewCareer /></div> <br/>
                <Abbreviation />
		    
        </div>
    </div>
}

export default Career;